import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import dtc_logo from '../img/datacontact_white.svg';
import '../sass/NavBar.scss';

function NavBar() {
  return (
    <>
      <Navbar>
        <Container>
          <Navbar.Brand className="nav-brand" href="/">
            <img src={dtc_logo} height="25" />
            <span>Promo fibra</span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <a href="/privacy.html">Privacy</a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
