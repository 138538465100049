import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import "./sass/main.scss";
import theImg from "./img/operatrice.png";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button, Modal, ModalFooter, ModalTitle } from "react-bootstrap";
import NavBar from "./components/NavBar";

function App() {
  const [value, setValue] = useState("");
  const [chek1, setChek1] = useState(false);
  const [chek2, setChek2] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setValue("+39");
    setChek1(false);
    setChek2(false);
  };
  const handleShow = () => {setShow(true);
   setValue("+39");
   setChek1(false);
   setChek2(false);
   };

  const callToAction = () => {
    const theNumber = "+" + value;
    console.log(theNumber);
    fetch(`//promofibra.it/mail.php?number=${theNumber}`, {
      method: "GET",
      mode: "no-cors",
    }).then((response) => handleShow());
  };

  return (
    <div className="App">
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="col-lg-6 side-a">
            <div className="photoHolder">
              <img src={theImg} />
              <div className="firstText">offerte</div>
              <div className="secondText">telefoniche</div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="callNow">Ti chiamiamo ora</div>
            <div className="consulenza">
              Consulenza Commerciale per la telefonia fissa
            </div>
            <div className="theField">
              <PhoneInput
                autoFormat={false}
                value={value}
                disableDropdown
                required
                enableLongNumbers
                enableAreaCodes
                placeholder="il tuo numero di telefono"
                country="it"
                onChange={(phone) => setValue(phone.toString())}
              />
              <br />
              <div className="form-check">
                <input
                  className="form-check-input"
                  defaultChecked={chek1}
                  checked={chek1}
                  type="checkbox"
                  value=""
                  onChange={() => setChek1(!chek1)}
                />

                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Ho letto la vostra <a href="/privacy.html">privacy policy</a> e vi
                  autorizzo, senza alcuna riserva, al contatto immediato ai
                  sensi dell’art. 4 dell’informativa privacy (questo è un link
                  alla pagina informativa)
                </label>
              </div>
              <br />
              <div className="form-check">
                <input
                  defaultChecked={chek2}
                  checked={chek2}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={() => setChek2(!chek2)}
                />

                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Presto il consenso al trattamento dei miei dati personali da
                  parte della società Titolare per ricevere informazioni
                  commerciali e promozionali relative alle offerte di telefonia
                  fissa e mobile.
                </label>
              </div>
              <button
                onClick={() => callToAction()}
                disabled={!(chek1 && chek2)}
                className={`callGreen ${
                  !(chek1 && chek2 && value.length > 11) ? "disabled" : ""
                }`}
              >
                Ti chiamiamo gratis <FontAwesomeIcon icon={faPhone} />
              </button>
              {/* {value.length > 11 ? "ok" : "ko"} */}
              {/* {"----"} */}
              {console.log(chek1, chek2)}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <ModalHeader closeButton>
          <ModalTitle>Invio Dati</ModalTitle>
        </ModalHeader>
        <Modal.Body>Le informazioni sono state inviate con successo</Modal.Body>
        <ModalFooter>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default App;
